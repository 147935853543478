import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class CalibrationService {
  public baseURL: string = environment.apiBaseUrl + "/calibration";

  constructor(private http: HttpClient) {}

  getLastCalibrationOutID() {
    return this.http.get(this.baseURL + "/outId");
  }
  getAllProcessingBaseOnQuery(processing) {
    return this.http.post(this.baseURL + "/report", processing);
  }
  getCalibrationReportSearch(Searchdata) {
    return this.http.post(this.baseURL + "/search", Searchdata);
  }
  saveCalibration(data) {
    return this.http.post(this.baseURL, data, { responseType: "arraybuffer" });
  }
  getAllPendingCalibration(processing) {
    return this.http.post(this.baseURL + "/processing", processing);
  }

  getAllPendingCalibrationVendor(processing) {
    return this.http.post(this.baseURL + "/vendorName", processing);
  }
  getUpdateCalibrationIn(calibration) {
    return this.http.post(this.baseURL + "/calibrationIN", calibration);
  }
  getUpdateServiceIn(service) {
    return this.http.post(this.baseURL + "/serviceIN", service);
  }
  getCalibrationById(data) {
    return this.http.post(this.baseURL + "/findCalibration", data);
  }
  calibrationExpDate(data) {
    return this.http.put(this.baseURL + "/calibrationExpDate", data);
  }
  getStockBystatus(data) {
    return this.http.put(this.baseURL + "/getStockBystatus", data);
  }
  getUpdateCalibration(data) {
    return this.http.put(this.baseURL + "/getUpdate", data);
  }
  getAllPendingfromgate(data) {
    return this.http.put(this.baseURL + "/pendinggate", data);
  }
  saveCalibrationyardInventory(data) {
    return this.http.post(this.baseURL + "/saveYardInvoice", data);
  }
  getAllInventoryReport(data) {
    return this.http.put(this.baseURL + "/getAllInventory", data);
  }
  getAllYardReport() {
    return this.http.get(this.baseURL + "/getAllYardReport");
  }
}
